import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Costs = () => (
  <Layout pageInfo={{ pageName: "tarieven", pageTitle: "Tarieven", bannerTitle: "website-header-05.png" }}>
    <Seo title="Tarieven" />
    <Container>
      <section className="general-page" id="costs">
        <Container>
          <Row>
            <Col>
              <h2>Tarieven Kind- en gezinscoaching</h2>
              <div class="block">

                <p>
                  Elk traject start met een kosteloze telefonische kennismaking.
                  Voorbereidingstijd, verslaglegging en gebruik van materialen zijn meegenomen in onderstaande
                  berekening.
                </p>

                <p>
                  <Row>
                    <Col> Dienst </Col>
                    <Col> Prijs </Col>
                  </Row>
                  <Row>
                    <Col> Intakegesprek </Col>
                    <Col> € 67,50 </Col>
                  </Row>
                  <Row>
                    <Col> Coaching voor kinderen </Col>
                    <Col> € 57,50 </Col>
                  </Row>
                  <Row>
                    <Col> Coaching voor ouders/opvoeders </Col>
                    <Col> € 57,50 </Col>
                  </Row>
                  <Row>
                    <Col> Tussentijdse (uitgebreide) evaluatie </Col>
                    <Col> € 57,50 </Col>
                  </Row>
                  <Row>
                    <Col> Observatie </Col>
                    <Col> € 67,50 </Col>
                  </Row>
                  <Row>
                    <Col> Eindevaluatie </Col>
                    <Col> € 57,50 </Col>
                  </Row>
                </p>

                <p>
                  Bovenstaande bedragen zijn inclusief 21% BTW.
                  Facturen worden steeds thuisgestuurd en dienen binnen twee weken te worden voldaan.
                </p>

                <p>
                  Op het traject zijn de <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link> van toepassing.
                  Indien een gemaakte afspraak binnen 24 uur voor aanvang wordt afgezegd, ben ik helaas genoodzaakt het
                  volledige bedrag te factureren.
                </p>

              </div>

              <h2>Tarieven Geboortepatronen</h2>
              <div className="block">

                <p>
                  Het in kaart brengen van een geboorteverhaal start met het zelfstandig invullen van een vragenlijst.
                  Vervolgens wordt een sessie gepland waarbij we samen dieper ingaan op het geboorteverhaal, die
                  ongeveer twee tot drie uur duurt. Ten slotte werk ik het geboorteverhaal verder uit en ontvang je
                  hiervan een uitgebreide rapportage.
                </p>

                <p>
                  Het complete pakket wordt aangeboden voor € 249,95 inclusief BTW.
                </p>

                <p>
                  De factuur wordt thuisgestuurd en dient, conform de <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link>, binnen twee weken te worden voldaan.
                </p>

              </div>


              <h2>Tarieven Analyse Kindertekeningen</h2>
              <div className="block">

                <p>
                  <Row>
                    <Col> Dienst </Col>
                    <Col> Prijs </Col>
                  </Row>
                  <Row>
                    <Col> Schriftelijke analyse </Col>
                    <Col> € 39,95 </Col>
                  </Row>
                  <Row>
                    <Col> Bespreking analyse </Col>
                    <Col> € 59,95 </Col>
                  </Row>
                  <Row>
                    <Col> Combinatie schriftelijke en bespreking analyse </Col>
                    <Col> € 69,95 </Col>
                  </Row>
                </p>


                <p>Het bedrag van het soort analyse dat u kiest dient voorafgaand aan de analyse, bij toezending van de
                  tekening en de daarbij behorende informatie, te worden voldaan.
                  Mijn streven is om de analyse zo snel mogelijk, in ieder geval binnen 10 dagen, uit te werken.</p>

                <p>De genoemde prijzen zijn inclusief 9% BTW.
                </p>

                <p>Voor de overige voorwaarden: zie de <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link>.
                </p>
              </div>


            </Col>
          </Row>
        </Container>
      </section>

      <section className="general-page" id="lala">
        <Container>
          <Row>
            <Col>
              <h2>Vergoedingen</h2>

              <div className="block">
                <p>
                  Er kan zonder verwijzing of indicatie gebruik gemaakt worden van coaching en begeleiding. In veel
                  gevallen wordt kind- en gezinscoaching niet vergoed door de Zorgverzekeraar. De prijzen zijn daarop
                  aangepast. U kunt natuurlijk wel altijd contact opnemen met uw Zorgverzekeraar om naar de
                  mogelijkheden te informeren. Daarnaast kunt u mogelijk de kosten opgeven bij uw jaarlijkse aangifte
                  bij de Belastingdienst.
                </p>

                <p>
                  Er zijn mogelijkheden om via een Persoons Gebonden Budget (PGB) coaching en begeleiding van Alles Kids
                  Kindercoaching in te schakelen. Wanneer uw kind deze ontvangt dan kunt u hiermee zelf zorg, hulp en
                  begeleiding inkopen.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default Costs
